
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserAuthProvider } from '@/types/User';

@Component
export default class LoginGeneralButton extends Vue {
  @Prop(Boolean) readonly eventButton?: Boolean;
  @Prop(Boolean) readonly shadow?: Boolean;

  auth(provider: UserAuthProvider) {
    window.location.href = `/auth/${provider}`;
  }
}
